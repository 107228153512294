import { User } from "./interfaces";

/**
 * getJWT obtiene los datos de usuario guardados en localStorage.
 */
export function readJWT(): string | null {
  return window.localStorage.getItem("token");
}

export function storeJWT(newJWT: string) {
  window.localStorage.setItem("token", newJWT);
}

export function deleteJWT() {
  window.localStorage.removeItem("token");
}

export const extractUserClaims = (jwtString: string): User | null => {
  var encodedClaims = jwtString.split(".")[1];
  var claims = window.atob(encodedClaims);
  return JSON.parse(claims.toString());
}