import { useState } from "react";
import { Box, Button, Text } from "@chakra-ui/react";

import { getHistory } from "../../utils/api";


export default function HistorySection({ selectedUsername }: { selectedUsername: string }): JSX.Element {

	const [history, setHistory] = useState("");

	function loadHistory() {
		getHistory(selectedUsername)
			.then(res => setHistory(res.data));
	}
	return (
		<>
			{/* Historial de uso */}
			<Box>
				<Button colorScheme="teal" onClick={loadHistory}>Ver historial del equipo</Button>
			</Box>

			<Text style={{ whiteSpace: "pre-line" }}>{history}</Text>
		</>
	);
}