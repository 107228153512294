import React, { useCallback, useState, useEffect, useRef } from "react";
import { Box, HStack, IconButton } from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";

import { Chart as ChartJS, BarController, BarElement, CategoryScale, LinearScale, Tooltip } from "chart.js";
import { Bar } from "react-chartjs-2";

import buttonPlugin, { drawButtons } from "./deviationChartButtonPlugin";
import { ButtonPluginOptions, RecentData } from "../../utils/interfaces";

ChartJS.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip);

const o2BackgroundColor = "#4299E1";
const o2BorderColor = "#2A4365";
const co2BackgroundColor = "#F56565";
const co2BorderColor = "#822727";


interface DeviationChartProps {
  recentData: RecentData;
  globalChamberID: number;
  setGlobalChamberID: (newID: React.SetStateAction<number>) => void;
}

/**
 * DeviationChartSection contiene un gráfico de barras que contiene "errores":
 * distancias entre las concentraciones actuales y las deseadas para O2 y CO2.
 * Este gráfico se dibuja con ChartJS. ¡Revisa la documentación!
 * 
 * Dentro del canvas donde se dibuja el gráfico, están dibujados botones debajo
 * de cada barra, que permiten seleccionar cámaras. Estos botones son producto
 * de un arduo trabajo que se encuentra en DeviationChartButtonPlugin.js.
 * ¡Revisa la documentación sobre plugins para ChartJS!
 * 
 * Al hacer click en estos botones, se usa la función globalChamberIDSetter
 * pasada como argumento para cambiar la ID actual. Esta función se pasa al
 * buttonPlugin en las opciones más adelante.
 * 
 * El gráfico se adapta al tamaño de la pantalla, pero si esta es demasiado
 * pequeña como para mostrar todas las barras a la vez, solo se muestra parte
 * del gráfico y aparecen automáticamente unos botones para avanzar y
 * retroceder la sección visible de este.
 */
export default function DeviationChart({ recentData, globalChamberID, setGlobalChamberID }: DeviationChartProps): JSX.Element {
  const { chamberIDs, statuses, lastMeasuredO2, lastMeasuredCO2, o2SetPoints, co2SetPoints } = recentData;
  const numTotalChambers = chamberIDs.length;
  const maxChamberID = chamberIDs[numTotalChambers-1];

  const isScreenLarge = (window.innerWidth >= 768);

  const o2Errors = Array<number>();
  const co2Errors = Array<number>();
  const statusMap = new Map<number, string>();
  var currentID = 1;
  for (var i = 0; i < numTotalChambers; i++) {
    const existingID = chamberIDs[i];
    while (currentID < existingID) {
      o2Errors.push(-1);
      co2Errors.push(-1);
      statusMap.set(currentID, "MISSING")
      currentID++;
    }

    if (statuses[i] === 1) {
      o2Errors.push(Math.abs(lastMeasuredO2[i] - o2SetPoints[i]));
      co2Errors.push(Math.abs(lastMeasuredCO2[i] - co2SetPoints[i]));
      statusMap.set(existingID, "ENABLED");
    } else {
      o2Errors.push(-1);
      co2Errors.push(-1);
      statusMap.set(existingID, (statuses[i] === 0) ? "DISABLED" : "DEACTIVATED");
    }

    currentID++;
  }

  // Es necesario referirse al gráfico para calcular cuántas cámaras mostrar
  const chartRef = useRef();

  // numVisibleChambers indica cuántas cámaras mostrar por pantalla
  // Por defecto se muestran todas, pero si la pantalla es muy pequeña se debe
  // ajustar esta cantidad para mostrar las cámaras por páginas
  const [numVisibleChambers, setNumVisibleChambers] = useState(calculateNumVisibleChambers());
  
  function calculateNumVisibleChambers() {
    const chartWidth = window.innerWidth - 150;
    // Decide si mostrar todas las cámaras, solo 20, solo 15, 10, etc.
    for (var tempNumVisibleChambers of [numTotalChambers, 20, 15, 10, 5, 3, 2, 1]) {
      // buttonSpace indica cuánto espacio tiene cada botón para mostrarse
      // Un botón cabe bien en un espacio de 64 o más pixeles
      const buttonSpace = chartWidth / tempNumVisibleChambers;
      if (buttonSpace > 42) {
        return tempNumVisibleChambers;
      }
    }
    return 1;
  }

  // Si se están usando páginas para mostrar las cámaras, minChamberID indica
  // desde qué ID mostrar cámaras, y es un estado que cambia al avanzar o
  // retroceder páginas
  const [minChamberID, setMinChamberID] = useState(calculateMinChamberID(numVisibleChambers));

  function calculateMinChamberID(numVisibleChambers: number) {
    return globalChamberID - ((globalChamberID-1) % numVisibleChambers);
  }

  function updateVisibleChambers() {
    const newNumVisibleChambers = calculateNumVisibleChambers();
    setNumVisibleChambers(newNumVisibleChambers);
    setMinChamberID(calculateMinChamberID(newNumVisibleChambers));
  }

  /**
   * handleResize calcula, en base al ancho de la pantalla, cuántas cámaras
   * deberían mostrarse a la vez.
   */
  const handleResize = useCallback(
    updateVisibleChambers,
    [numTotalChambers, recentData],
  );

  /*
  Cada vez que la ventana cambia de tamaño, se llama a handleResize() para
  recalcular cuántas cámaras deberían mostrarse a la vez.
  */
  useEffect(() => {
    handleResize(); // se llama una vez al principio para evitar bugs
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize])

  /*
  Cada vez que se actualicen los datos, puede que se habiliten o deshabiliten
  cámaras. Por eso, hay que redibujar los botones para reflejar eso. 
  */
  useEffect(() => {
    if (chartRef.current && statusMap.size === maxChamberID) {
      drawButtons(chartRef.current, statusMap);
    }
  }, [maxChamberID]);


  /**
   * BackwardButton retrocede una página si hay demasiadas cámaras.
   */
  function BackwardButton(): JSX.Element {
    return (
      <IconButton
        aria-label="Página anterior"
        size={{"base": "sm", "lg": "md"}}
        icon={<ArrowLeftIcon />}
        colorScheme="teal"
        isDisabled={minChamberID === 1}
        onClick={() => setMinChamberID(Math.max(0, minChamberID - numVisibleChambers))}
      />
    );
  }

  /**
   * ForwardButton retrocede una página si hay demasiadas cámaras.
   */
  function ForwardButton(): JSX.Element {
    return (
      <IconButton
        aria-label="Siguiente página"
        size={{"base": "sm", "lg": "md"}}
        icon={<ArrowRightIcon />}
        colorScheme="teal"
        isDisabled={minChamberID + numVisibleChambers - 1 >= numTotalChambers}
        onClick={() => setMinChamberID(minChamberID + numVisibleChambers)}
      />
    );
  }

  const data = {
    labels: chamberIDs,
    datasets: [
      {
        label: "O₂",
        data: o2Errors,
        backgroundColor: o2BackgroundColor,
        borderColor: o2BorderColor,
        minBarLength: 10,
      },
      {
        label: "CO₂",
        data: co2Errors,
        backgroundColor: co2BackgroundColor,
        borderColor: co2BorderColor,
        minBarLength: 10,
      },
    ]
  };
  
  const options = {
    // Parámetros para mayor rendimiento
    animation: false as false,

    // Parámetros para responsividad
    responsive: true,
    maintainAspectRatio: false,

    // Opciones de ejes
    scales: {
      x: {
        type: "category" as "category",
        min: minChamberID,
        max: minChamberID + numVisibleChambers - 1,
        ticks: {
          font: { size: 16 },
          padding: 6,
        },
      },
      y: {
        type: "linear" as "linear",
        min: 0,
        max: Math.max(0.5, Math.max(...o2Errors, ...co2Errors)),
        title: {
          display: true,
          text: "abs(error) [%]",
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 1,
        },
        ticks: {
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 0,
        },
      },
    },

    // Opciones de plugins
    plugins: {
      legend: { display: false },
      // Es NECESARIO pasar statusMap y globalChamberIDSetter como OPTIONS a
      // buttonPlugin y no directamente
      buttonPlugin: {
        statusMap: statusMap,
        globalChamberID: globalChamberID,
        setGlobalChamberID: setGlobalChamberID,
      } as ButtonPluginOptions,
    },
  };


  /*
  Si el número de ticks visibles calculado para la pantalla actual, es menor a
  la cantidad total de cámaras, se muestra solo una sección del gráfico y
  aparecen botones que permiten avanzar y retroceder páginas.
  */
  return (
    <HStack w="100%">
      { (numVisibleChambers < numTotalChambers) && <BackwardButton /> }
      {/* minWidth="0" es NECESARIO para que el gráfico se encoja al achicar la página*/}
      <Box minWidth="0" w="full" h={{"base": "100px", "sm": "120px", "md": "140px", "2xl": "180px"}}>
        <Bar ref={chartRef} data={data} options={options} plugins={[buttonPlugin]} />
      </Box>
      { (numVisibleChambers < numTotalChambers) && <ForwardButton /> }
    </HStack>
    
  );
}