import { Chart } from "chart.js";
import drawRoundRect from "../../../utils/drawRoundRect";
import { NoDataPluginOptions } from "../../../utils/interfaces";

/**
 * noDataPlugin dibuja un rectángulo gris detrás del gráfico ChamberChart
 * cuando no hay datos.
 */
const noDataPlugin = {
  id: "noDataPlugin",

  afterDraw: (chart: Chart, args: any, options: NoDataPluginOptions) => {
    if (options.noData) {
      const { ctx } = chart;
      ctx.save();

      ctx.globalAlpha = 0.5;
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = "#888888";
      drawRoundRect(ctx, 0, 0, chart.width, chart.height, 6);
      ctx.fill();

      ctx.globalAlpha = 1.0;
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.font = "48px sans-serif"
      ctx.fillStyle = "#222222";
      ctx.fillText("Sin datos", Math.floor(chart.width/2), Math.floor(chart.height/2));

      ctx.restore();
    }
  },
};

export default noDataPlugin;