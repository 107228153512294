import {FormEvent, useState} from "react";
import { Center, VStack, Heading, Image, InputGroup, Input, InputLeftElement, Button, Text } from "@chakra-ui/react";
import { AtSignIcon, LockIcon } from "@chakra-ui/icons";

import { login } from "../utils/api";

import atmosfixLogo from "./atmosfix_logo.webp";
import { LoginStatus, UserState } from "../utils/interfaces";
import { extractUserClaims, storeJWT } from "../utils/jwt";


interface LoginScreenParams {
  setUserState: (newUser: React.SetStateAction<UserState>) => void;
}

/**
 * LoginScreen es la pantalla de inicio.
 * Contiene un formulario donde se ingresa el nombre de usuario y contraseña.
 * Si los datos son correctos, se usa la función userSetter pasada como
 * argumento para guardar en sessionStorage los datos del usuario.
 */
export default function LoginScreen({ setUserState }: LoginScreenParams): JSX.Element {
  const [loginStatus, setLoginStatus] = useState<LoginStatus>({ isLoggingIn: false, error: null });

  function submitHandler(event: FormEvent) {
    event.preventDefault(); // Esto evita el reinicio de la página
    setLoginStatus({ isLoggingIn: true, error: null });

    const { username, password } = document.forms[0];
    
    /**
     * POST /login llama a la función POSTLogin, definida en
     * afhttp/post_login.go
     */
    login(username.value, password.value)
    .then(res => {
      const token = res.data.token;
      storeJWT(token);
      setLoginStatus({ isLoggingIn: false, error: null });
      setUserState({ isValidating: false, user: extractUserClaims(token) });
    })
    .catch(err => {
      const errorMessage: string = (err.response ? err.response.data.error : "Sin conexión al servidor");
      setLoginStatus({ isLoggingIn: false, error: errorMessage })
    });
  }

  return (
    <Center height={window.innerHeight} bg="gray.500">
      <form method="post" onSubmit={submitHandler}>
        <VStack borderRadius="md" p={{"base": 8, "md": 12}} bg="white" spacing={6} shadow="xl">
          <Image src={atmosfixLogo} width={{"base": "250px", "md": "300px"}} />
          <Heading>Iniciar sesión</Heading>
          { loginStatus.error !== null &&
            <Text fontSize="lg" color="red">{loginStatus.error}</Text>
          }

          {/* Campo de nombre de usuario */}
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<AtSignIcon/>} />
            <Input isRequired name="username" placeholder="Nombre de usuario" borderColor="gray.500"/>
          </InputGroup>
          
          {/* Campo de contraseña */}
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<LockIcon/>} />
            <Input isRequired name="password" type="password" placeholder="Contraseña" borderColor="gray.500"/>
          </InputGroup>

          <Button isLoading={loginStatus.isLoggingIn} type="submit" colorScheme="teal" shadow="lg">Ingresar</Button>
        </VStack>
      </form>
    </Center>
  );
}