/**
 * drawRoundRect dibuja dentro de un canvas HTML un rectángulo redondeado.
 * 
 * drawRoundRect emula el método roundRect de Context, el cual no está
 * soportado en Firefox y rompe la página al intentar usarlo ahí.
 * 
 * Esta función se usa en los plugins personalizados creados para los gráficos
 * de Chart.js: homeScreen/deviationChartSection/deviationChartButtonPlugin.js,
 * y homeScreen/chamberSection/chamberChart/noDataPlugin.js.
 */
export default function drawRoundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
) {
  const points = [
    [x                      , y                       ],
    [Math.floor(x + width/2), y                       ],
    [x + width              , y                       ],
    [x + width              , Math.floor(y + height/2)],
    [x + width              , y + height              ],
    [Math.floor(x + width/2), y + height              ],
    [x                      , y + height              ],
    [x                      , Math.floor(y + height/2)],
  ];

  ctx.moveTo(points[7][0], points[7][1]);
  for (var i = 0; i < 8; i += 2) {
    ctx.arcTo(
      points[i][0],
      points[i][1],
      points[i+1][0],
      points[i+1][1],
      radius,
    );
  }
  ctx.lineTo(points[7][0], points[7][1]);
}