/**
 * secondsToTimeString convierte segundos a un formato de días, horas y
 * minutos. Los segundos que sobran se descartan.
 * 
 * Ejemplos:
 * - secondsToTimeString(145) = "2 minutos"
 * - secondsToTimeString(7380) = "2 horas y 3 minutos"
 * - secondsToTimeString(90070) = "1 día, 1 hora y 1 minuto"
 */
export default function secondsToTimeString(seconds: number): string {
  var days = 0;
  var hours = 0;
  var minutes = Math.floor(seconds / 60);
  if (minutes >= 60) {
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    if (hours >= 24) {
      days = Math.floor(hours / 24);
      hours = hours % 24;
    }
  }

  var message = "";
  if (days > 0) {
    message += `${days} día`;
    if (days > 1) {
      message += "s";
    }
    if (hours > 0 && minutes > 0) {
      message += ", "
    }
    else if (hours > 0 || minutes > 0) {
      message += " y ";
    }
  }

  if (hours > 0) {
    message += `${hours} hora`;
    if (hours > 1) {
      message += "s";
    }
    if (minutes > 0) {
      message += " y "
    }
  }

  if (minutes > 0) {
    message += `${minutes} minuto`;
    if (minutes > 1) {
      message += "s";
    }
  }

  return message;
}