/**
 * Este proyecto usa el framework Chakra UI y la librería Chart.js.
 * ¡Por favor, lee las documentaciones antes de tomar este proyecto!
 * 
 * Links necesarios:
 * 
 * https://chakra-ui.com/getting-started
 * https://chakra-ui.com/docs/styled-system/responsive-styles
 * 
 * https://react-chartjs-2.js.org/
 * https://react-chartjs-2.js.org/examples/vertical-bar-chart
 * https://react-chartjs-2.js.org/examples/line-chart
 * https://www.chartjs.org/docs/latest/developers/plugins.html
 * https://www.chartjs.org/docs/latest/api/interfaces/Plugin.html
 */

import { useState, useEffect } from "react";
import { ChakraBaseProvider, extendBaseTheme } from "@chakra-ui/react";
import chakraTheme from "@chakra-ui/theme";

import HomeScreen from "./homeScreen/HomeScreen";
import LoginScreen from "./loginScreen/LoginScreen";
import { refreshToken } from "./utils/api";
import { deleteJWT, extractUserClaims, readJWT, storeJWT } from "./utils/jwt";
import { UserState } from "./utils/interfaces";

const HOUR = 1000 * 60 * 60;

const { Alert, Button, CloseButton, Heading, Input, Modal, Radio, Select, Table, Tag } = chakraTheme.components;
const theme = extendBaseTheme({
  components: { Alert, Button, CloseButton, Heading, Input, Modal, Radio, Select, Table, Tag },
});

/**
 * App es el componente principal de la página.
 * 
 * Dependiendo de si el usuario inició sesión o no, muestra la pantalla de
 * inicio de sesión (LoginScreen) o la pantalla principal (HomeScreen). En
 * ambos componentes se requiere acceso para modificar el usuario actual, por
 * lo que a ambos se le pasa la función setUser creada con el hook useState.
 * 
 * Estructura de App:
 * 
 * - App
 *   - LoginScreen
 *   - HomeScreen
 *     - Header
 *     - DeviationChartSection
 *     - HUDSection
 *     - ChamberSection
 *       - ChamberChartSection
 *       - ChamberInfoSection
 *         - ChamberTable
 *         - DownloadButton
 *     - StatusSection 
 */
export default function App(): JSX.Element {
  const [userState, setUserState] = useState<UserState>({ isValidating: true, user: null });

  function refreshAndSetToken() {
    const jwtString = readJWT();
    if (!jwtString) {
      setUserState({ isValidating: false, user: null });
      return;
    }

    refreshToken()
      .then(res => {
        storeJWT(res.data.token);
        setUserState({
          isValidating: false,
          user: extractUserClaims(jwtString),
        });
      })
      .catch(err => {
        // Solo si hubo respuesta del servidor, resetear JWT
        // Esto soluciona un error con las tablets que fallan al conectar
        // con el backend y eliminan su token prematuramente
        if (err.response) {
          deleteJWT();
          setUserState({ isValidating: false, user: null });
        }
      });
  }

  useEffect(() => {
    refreshAndSetToken();
    setInterval(refreshAndSetToken, HOUR);
  }, []);

  /**
   * Normalmente se envuelve la app en un ChakraProvider. Pero para no cargar
   * todos los elementos de Chakra UI, se usa ChakraBaseProvider para cargar
   * solo los componentes seleccionados, definidos en theme. De esta manera, la
   * página pesa menos.
   */
  return (
    <ChakraBaseProvider theme={theme}>
      {
      userState.user !== null
      ? <HomeScreen currentUser={userState.user} setUserState={setUserState}/>
      : <LoginScreen setUserState={setUserState}/>
      }
    </ChakraBaseProvider>
  );
}
