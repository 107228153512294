import { ChamberData, Summary, UserConfig } from "./interfaces";

export const EMPTY_SUMMARY: Summary = {
    recentData: null,
    status: null,
    version: "",
}

export const EMPTY_CHAMBER_DATA: ChamberData = {
    unixTimes: [],
    o2: [],
    o2SetPointGroups: [],
    co2: [],
    co2SetPointGroups: [],
    airTemperatures: [],
    pulpTemperatures: [],
    humidities: [],
}

export const EMPTY_CONFIG: UserConfig = {
    enableUserAlarms: false,    
    mailRecipients: "",
};