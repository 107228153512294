import { Chart, ChartEvent } from "chart.js";

import drawRoundRect from "../../utils/drawRoundRect";
import { ButtonPluginOptions } from "../../utils/interfaces";

const EnabledDefaultColor = "#38A169";     // green.500
const EnabledFocusColor = "#2F855A";       // green.600
const EnabledActiveColor = "#276749";      // green.700

const DisabledDefaultColor = "#D69E2E";    // yellow.500
const DisabledFocusColor = "#B7791F";      // yellow.600
const DisabledActiveColor = "#975A16";     // yellow.700

const DeactivatedDefaultColor = "#E53E3E"; // red.500
const DeactivatedFocusColor = "#C53030";   // red.600
const DeactivatedActiveColor = "#9B2C2C";  // red.700

const MissingColor = "#888888";            // gray.500

const HighlightColor = "#4299E1";          // blue.500

interface Coords {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

var buttonCoordinates = new Map<number, Coords>();
var activeButtonLabel = 1;
var focusedButtonLabel = -1;

/**
 * drawButtons dibuja rectángulos en el canvas de DeviationChartSection, que
 * simulan ser botones gracias a clickHandler y mouseMoveHandler.
 * 
 * Para cada rectángulo, almacena sus coordenadas para que puedan ser usadas
 * por clickHandler y mouseMoveHandler.
 */
export function drawButtons(chart: Chart, statusMap: Map<number, string>) {
  const x_scale = chart.scales.x;
  if (!x_scale || !x_scale.getLabelItems()) {
    return;
  }
  
  const ctx = chart.ctx;
  ctx.save();

  const { left, top } = ctx.canvas.getBoundingClientRect();

  buttonCoordinates.clear();

  x_scale.getLabelItems().forEach(tick => {
    const width = 36;
    const height = 28;
    const x = Math.floor(tick.options.translation[0]) - width/2;
    const y = Math.floor(tick.options.translation[1]) - 8;

    const label = parseInt(tick.label as string);
    const status = statusMap.get(label);

    ctx.clearRect(x-2, y-2, width + 4, height + 4);

    // Rectángulos
    if (status === "MISSING") {
      ctx.fillStyle = MissingColor;
    }
    else if (label === activeButtonLabel) {
      ctx.fillStyle = (status === "ENABLED") ? EnabledActiveColor : (status === "DISABLED") ? DisabledActiveColor : DeactivatedActiveColor;
    }
    else if (focusedButtonLabel === label) {
      ctx.fillStyle = (status === "ENABLED") ? EnabledFocusColor : (status === "DISABLED") ? DisabledFocusColor : DeactivatedFocusColor;
    }
    else {
      ctx.fillStyle = (status === "ENABLED") ? EnabledDefaultColor : (status === "DISABLED") ? DisabledDefaultColor : DeactivatedDefaultColor;
    }
    ctx.beginPath();
    drawRoundRect(ctx, x, y, width, height, 6);
    ctx.fill();

    if (label === activeButtonLabel) {
      ctx.strokeStyle = HighlightColor;
      ctx.beginPath();
      drawRoundRect(ctx, x-2, y-2, width + 4, height + 4, 6);
      ctx.stroke();
    }

    // Textos
    ctx.fillStyle = "white";
    ctx.font = "16px sans-serif";
    ctx.textAlign = "center";
    ctx.beginPath();
    ctx.fillText(label.toString().padStart(2, "0"), x + width/2, y + height/2 + 6);

    buttonCoordinates.set(label, {
      top: y + top,
      bottom: y + top + height,
      left: x + left,
      right: x + left + width,
    });
  });

  ctx.restore();
}

/**
 * clickHandler registra un evento de click en el gráfico y revisa que haya
 * ocurrido dentro de un botón (es decir, que esté dentro de las coordenadas
 * del rectángulo del botón). De ser así, llama a globalChamberIDSetter para
 * cambiar la ID de la cámara seleccionada en toda la página, y redibuja los
 * botones.
 */
function clickHandler(event: ChartEvent, options: ButtonPluginOptions) {
  const { statusMap, setGlobalChamberID } = options;
  // @ts-ignore
  const { x, y } = event.native;

    // se usa Object.keys(buttonCoordinates) en vez de una variable global ids, en caso de que no estén todos los botones
  for (var [id, coords] of buttonCoordinates) {
    const { top, bottom, left, right } = coords;
    if (x >= left && x <= right && y >= top && y <= bottom && statusMap.get(id) !== "MISSING") {
      activeButtonLabel = id;
      setGlobalChamberID(id);
      // @ts-ignore
      drawButtons(event.chart, options.statusMap);
      break;
    }
  }
}

/**
 * mouseMoveHandler registra movimientos de mouse en el gráfico y revisa si el
 * mouse se movió dentro de un botón (es decir, que esté dentro de las
 * coordenadas del rectángulo del botón). De ser así, indica cuál es el ID del
 * botón que está siendo señalado, y redibuja los botones de manera que se
 * se oscurezca el botón señalado. Además, cambia el puntero del mouse de ser
 * necesario.
 */
function mouseMoveHandler(event: ChartEvent, options: ButtonPluginOptions) {
  const { statusMap } = options;
  // @ts-ignore
  const { x, y } = event.native;
  
  var isCursorOverButton = false;
  // se usa Object.keys(buttonCoordinates) en vez de una variable global ids, en caso de que no estén todos los botones
  for (var [id, coords] of buttonCoordinates) {
    const { top, bottom, left, right } = coords;
    if (x >= left && x <= right && y >= top && y <= bottom && statusMap.get(id) !== "MISSING") {
      isCursorOverButton = true;
      // @ts-ignore
      event.native.target.style.cursor = "pointer";
      if (focusedButtonLabel !== id) {
        focusedButtonLabel = id;
        // @ts-ignore
        drawButtons(event.chart, options.statusMap);
      }
      break;
    }
  }

  if (!isCursorOverButton) {
    // @ts-ignore
    event.native.target.style.cursor = "default";
    if (focusedButtonLabel !== -1) {
      focusedButtonLabel = -1;
      // @ts-ignore
      drawButtons(event.chart, options.statusMap);
    }
  }
}

/**
 * buttonPlugin permite dibujar en el canvas del gráfico un botón por cada
 * cámara visible. Este botón es clickeable y modifica la ID de la cámara que
 * se está viendo en la página.
 */
const buttonPlugin = {
  id: "buttonPlugin",
  start(chart: Chart, args: any, options: ButtonPluginOptions) {
    activeButtonLabel = options.globalChamberID; // TODO
    focusedButtonLabel = -1;
  },
  afterDatasetDraw(chart: Chart, args: any, options: ButtonPluginOptions) {
    activeButtonLabel = options.globalChamberID
    drawButtons(chart, options.statusMap);
  },
  afterEvent(chart: Chart, args: any, options: ButtonPluginOptions) {
    if (args.event.type === "click") {
      clickHandler(args.event, options);
    }
    if (args.event.type === "mousemove") {
      mouseMoveHandler(args.event, options);
    }
  },
};

export default buttonPlugin;