import { useCallback, useState, useEffect, useRef } from "react";
import { Center, CircularProgress, Text, VStack, useToast } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";

import Header from "./header/Header";
import DeviationChart from "./deviationChart/DeviationChart";
import HUD from "./hud/HUD";
import ChamberSection from "./chamberSection/ChamberSection";
import StatusSection from "./statusSection/StatusSection";

import secondsToTimeString from "../utils/secondsToTimeString";
import { getAllUsers, getSummary, postCurrentChamberID } from "../utils/api";
import { Summary, User, UserState } from "../utils/interfaces";
import { EMPTY_SUMMARY } from "../utils/constants";
import { readSelectedUsername, storeSelectedUsername, isNull } from "../utils/selectedUsername";
import HistorySection from "./historySection/HistorySection";
import ScrollToTopButton from "./scrollToTopButton/ScrollToTopButton";


interface HomeScreenParams {
  currentUser: User;
  setUserState: (newUser: React.SetStateAction<UserState>) => void;
}

/**
 * HomeScreen es la pantalla principal de la página.
 * Aquí se muestra todo lo relevante al usuario.
 * 
 * Estructura de HomeScreen:
 * 
 * - HomeScreen
 *   - Header
 *   - DeviationChartSection
 *   - HUDSection
 *   - ChamberSection
 *     - ChamberChartSection
 *     - ChamberInfoSection
 *       - ChamberTable
 *       - DownloadButton
 *   - StatusSection 
 * 
 * Descripción general de la estructura:
 * 
 * - Header:
 * Cabecera que contiene el título de la página (que incluye el nombre del
 * usuario), y un botón para cerrar sesión, el cual requiere la función
 * userSetter que se le pasa a HomeScreen como argumento.
 * 
 * - DeviationChartSection:
 * Gráfico de barras que contiene "errores": las distancias entre las
 * concentraciones actuales y las deseadas para O2 y CO2.
 * 
 * Recuerda que la idea de AtmosFix es modificar la atmósfera dentro de unas
 * cámaras para lograr concentraciones de gases. específicas para varios
 * propósitos. Estas concentraciones deseadas se llaman "set points", y los
 * gráficos de barras muestran qué tan lejos de los set points están las
 * concentraciones actuales de O2 y CO2 en cada cámara.
 * 
 * En DeviationChartSection hay varios botones para seleccionar cámaras. Para
 * controlar qué cámara se visualiza, en HomeScreen se usa el hook useState
 * para crear un estado **globalChamberID** y la función **setGlobalChamberID**
 * que cambia ese estado. Esta función se pasa como argumento a
 * DeviationChartSection para controlar qué ID de cámara se usa actualmente.
 * 
 * - HUDSection:
 * Contiene información sobre el último y próximo control, los cilindros de N2
 * llenos y vacíos, y la hora local del cliente.
 * 
 * - ChamberSection:
 * Muestra los datos específicos de una cámara, según el valor de
 * globalChamberID. Consiste de un gráfico de líneas (ChamberChartSection) con
 * todas las mediciones, el nombre y estado actual de la cámara, una tabla con
 * las últimas mediciones (ChamberTable), y un botón para descargar el Excel
 * con las mediciones (DownloadButton).
 * 
 * - StatusSection:
 * Al final de la página hay una sección que muestra el estado actual del
 * equipo, alertas si es que hay, y un historial completo.
 */
export default function HomeScreen({ currentUser, setUserState }: HomeScreenParams): JSX.Element {
  // Se busca capturar en la URL el "query param" ?username=<nombre>
  const searchParams = new URLSearchParams(document.location.search);
  const [users, setUsers] = useState<User[]>([])
  const [selectedUsername, setSelectedUsername] = useState<string | null>(readSelectedUsername());

  const [summary, setSummary] = useState<Summary>(EMPTY_SUMMARY);
  const { recentData, status, version } = summary;

  const [globalChamberID, setGlobalChamberID] = useState(1);
  const [hasError, setHasError] = useState(false);
  const toast = useToast();

  const summaryRef = useRef(summary);

  useEffect(() => {
    if (currentUser.isAdmin) {
      getAllUsers()
        .then(res => setUsers(res.data))
        .catch(err => console.error(err));
    }
  }, [currentUser.isAdmin]);

  useEffect(() => {
    var newSelectedUsername: string | null = null;
    if (currentUser.isAdmin) {
      newSelectedUsername = searchParams.get("username");
      if (isNull(newSelectedUsername)) {
        newSelectedUsername = readSelectedUsername();
      }
      if (isNull(newSelectedUsername)) {
        if (users.length > 0) {
          newSelectedUsername = users[0].username;
        }
      }
    } else {
      newSelectedUsername = currentUser.username;
    }
    storeSelectedUsername(newSelectedUsername);
    setSelectedUsername(newSelectedUsername);
  }, [users, currentUser.isAdmin, currentUser.username]);

  /**
   * getData obtiene los datos más recientes (para DeviationChartSection y para
   * ChamberTable en ChamberSection) y los datos de estado (para
   * StatusSection). Esta función se llama al iniciar HomeScreen y una vez cada
   * minuto.
   */
  const getUserSummary = useCallback((selectedUsername: string, { showUpdateToast = false }) => {
    // Si había un error antes, settearlo a false temporalmente permite volver
    // a mostrar el spinner mientras se vuelven a pedir los datos
    setHasError(false);

    /*
    GET /:selectedUsername/summary llama a la función afhttp.GETUserSummary
    en Golang, definida en main-backend/afhttp/get_user_summary.go
    */
    getSummary(selectedUsername)
      .then(res => {
        const newSummary = res.data;
        const oldSummary = summaryRef.current;

        // Comparamos newSummary con oldSummary. Si hay cambios, actualizar
        if (JSON.stringify(newSummary) !== JSON.stringify(oldSummary)) {
          // Actualizar
          summaryRef.current = newSummary;
          setSummary(newSummary);
          setGlobalChamberID(newSummary.status.currentChamberID);

          // Mostrar un toast de actualización si hubo cambios en recent_data
          if (showUpdateToast && JSON.stringify(newSummary.recentData) !== JSON.stringify(oldSummary.recentData)) {
            toast({
              title: "Datos actualizados.",
              description: "Hora actual: " + new Date().toLocaleTimeString(),
              status: "info",
              duration: 5000, // 5 segundos
              isClosable: true,
            });
          }
        }

        // ¿Faltan datos? Algo debió haber salido mal
        if (newSummary.recentData.unixTimes.length === 0 || Object.keys(newSummary.status).length === 0) {
          setHasError(true);
        }
      })
      .catch(_ => setHasError(true));

    // eslint-disable-next-line
  }, [toast, setSummary]);

  /*
  Se obtienen los datos del usuario cada 30 segundos
  */
  useEffect(() => {
    if (!selectedUsername) {
      return () => { };
    }
    // Para obtener los datos al principio
    getUserSummary(selectedUsername, { showUpdateToast: false });

    // Para volver a obtener los datos cada 30000 milisegundos
    // Esto también gatilla el obtener chamberData en ChamberSection
    const interval = setInterval(() => getUserSummary(selectedUsername, { showUpdateToast: true }), 30000);
    return () => clearInterval(interval);
  }, [selectedUsername, getUserSummary, toast]);

  /*
  Toasts de error (fuera de línea) y de alertas del equipo
  */
  useEffect(() => {
    if (status === null || !status.enableUserAlarms) {
      return;
    }

    const thresholdInSeconds = 180; // 3 minutos
    const currentUnixTime = Date.now() / 1000;
    const offlineTimeInSeconds = currentUnixTime - status.lastSoftwareUnixTime;

    if (offlineTimeInSeconds > thresholdInSeconds) {
      toast({
        title: `¡Equipo fuera de línea hace ${secondsToTimeString(offlineTimeInSeconds)}!`,
        status: "error",
        duration: 29500, // 29 segundos y medio, antes de que salga el próximo
        isClosable: true,
        position: "top",
      })
    } else {
      const warning = status.warning;
      if (warning) {
        toast({
          title: "Alerta",
          description: warning,
          status: "warning",
          duration: 29500, // 29 segundos y medio, antes de que salga el próximo
          isClosable: true,
          position: "top",
        })
      }
    }
  }, [status, toast]);

  /*
  Actualizar status_data.current_chamber_id en la base de datos
  */
  useEffect(() => {
    if (selectedUsername) {
      postCurrentChamberID(selectedUsername, globalChamberID);
    }
  }, [selectedUsername, globalChamberID])

  /*-----*/

  /*
  Si hay error, mostrar pantalla de error
  Esto debe estar obligatoriamente antes del spinner
  */
  if (hasError) {
    return (
      <Center height={window.innerHeight}>
        <VStack spacing="8">
          <WarningIcon boxSize="150" color="red.500" />
          <Text fontSize="2xl" align="center">
            Hubo un error al cargar los datos.<br />
            Espera 30 segundos.
          </Text>
        </VStack>
      </Center>
    );
  }

  /*
  Mientras no se cargen los datos, mostrar un spinner en pantalla
  ("Cargando datos...")
  */
  if (!recentData || recentData.unixTimes.length === 0 || !status || Object.keys(status).length === 0) {
    return (
      <Center height={window.innerHeight}>
        <VStack spacing="8">
          <CircularProgress isIndeterminate size="150" color="teal" />
          <Text fontSize="2xl">Cargando datos...</Text>
        </VStack>
      </Center>
    );
  }

  /*
  Cuando se carguen los datos, mostrar todos los componentes según la
  estructura indicada
  */
  return (
    <>
      <Header currentUser={currentUser} setUserState={setUserState} users={users} selectedUsername={selectedUsername} setSelectedUsername={setSelectedUsername} />
      <VStack px={{ "base": 4, "2xl": 8 }} py={{ "base": 2, "2xl": 4 }} spacing={{ "base": 2, "md": 3, "2xl": 4 }} >
        <DeviationChart recentData={recentData} globalChamberID={globalChamberID} setGlobalChamberID={setGlobalChamberID} />
        <HUD status={status} />
        <ChamberSection selectedUsername={selectedUsername} globalChamberID={globalChamberID} recentData={recentData} />
        <StatusSection status={status} />
        <HistorySection selectedUsername={selectedUsername} />
        <p>Versión {version}</p>
      </VStack>
      <ScrollToTopButton />
    </>
  );
}
