import { Button } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

import { getCSVDownload } from "../../../../utils/api";


interface DownloadButtonProps {
  selectedUsername: string;
  localChamberID: number;
  filename: string;
}

/**
 * DownloadButton permite descargar el archivo Excel asociado a la cámara
 * actual.
 */
export default function DownloadButton({ selectedUsername, localChamberID, filename }: DownloadButtonProps): JSX.Element {
  function downloadHandler() {
    /*
    GET /:username/:chamber_id/:filename llama a la función GETDownload
    definida en atmosfix-app-api/afhttp/get_download.go
    */
    getCSVDownload(selectedUsername, localChamberID, filename)
    .then(res => {
      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(err => console.log(err));
  }
  
  return (
    <Button
      size={{"base": "sm", "md": "md"}}
      leftIcon={<DownloadIcon />}
      colorScheme="teal"
      variant="outline"
      whiteSpace="normal"
      height="auto"
      onClick={downloadHandler}
    >
      Descargar {filename}
    </Button>
  )
}