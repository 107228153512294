import axios from "axios";
import { ChamberData, Summary, TokenResponseBody, User, UserConfig } from "./interfaces";
import { readJWT } from "./jwt";

type MyWindow = Window & typeof globalThis & { BACKEND_URL: string };

/**
 * axiosClient usa como URL base la variable window.BACKEND_URL.
 * 
 * Consulta el archivo frontend/public/backendURL.js, donde se define
 * window.BACKEND_URL.
 * 
 * Una vez que ejecutes npm run build, ese archivo estará en build.
 * Ese archivo se sobreescribirá para contener la ubicación del backend.
 */
const axiosClient = axios.create({
	baseURL: (window as MyWindow).BACKEND_URL,
});

export default axiosClient;

function authorization() {
	return { headers: { Authorization: `Bearer ${readJWT()}` } };
}

export function login(username: string, password: string) {
	return axiosClient.post<TokenResponseBody>(
		"/login",
		{ username: username, password: password },
	);
}

export function refreshToken() {
	return axiosClient.get<TokenResponseBody>(
		"/refresh_token",
		authorization(),
	);
}

export function getAllUsers() {
	return axiosClient.get<User[]>(
		"/users",
		authorization(),
	);
}

export function getSummary(username: string) {
	return axiosClient.get<Summary>(
		`/${username}/summary`,
		authorization(),
	);
}

export function getChamberData(username: string, chamberID: number) {
	return axiosClient.get<ChamberData>(
		`/${username}/${chamberID}`,
		authorization(),
	);
}

export function getHistory(username: string) {
	return axiosClient.get<string>(
		`${username}/history`,
		authorization(),
	)
}

export function postCurrentChamberID(username: string, chamberID: number) {
	return axiosClient.post<null>(
		`/${username}/current_chamber_id`,
		chamberID,
		authorization(),
	);
}

export function getCSVDownload(username: string, chamberID: number, filename: string) {
	return axiosClient.get<BlobPart>(
		`/${username}/${chamberID}/${filename}`,
		{ ...authorization(), responseType: "blob" },
	);
}

export function getUserConfig(username: string) {
	return axiosClient.get<UserConfig>(
		`/${username}/config`,
		authorization(),
	);
}

export function postUserConfig(username: string, userConfig: UserConfig) {
	return axiosClient.post<UserConfig>(
		`/${username}/config`,
		userConfig,
		authorization(),
	);
}