export function readSelectedUsername(): string | null {
    return window.sessionStorage.getItem("selectedUsername");
}

export function storeSelectedUsername(newUsername: string | null) {
    if (isNull(newUsername)) {
        deleteSelectedUsername();
    } else {
        window.sessionStorage.setItem("selectedUsername", newUsername);
    }
}

export function isNull(text: string | null) {
    return !text || text === "null";
}

export function deleteSelectedUsername() {
    window.sessionStorage.removeItem("selectedUsername");
}