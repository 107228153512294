import { Alert, AlertIcon, AlertTitle, AlertDescription, Box, VStack } from "@chakra-ui/react";

import { StatusRecord } from "../../utils/interfaces";
import secondsToTimeString from "../../utils/secondsToTimeString";


interface StatusSectionProps {
  status: StatusRecord;
}

interface StatusAlertProps {
  status: "warning" | "info" | "success" | "error" | "loading";
  title: string;
  description: string;
}

/**
 * StatusSection entrega la información del estado del equipo y el historial de
 * uso.
 * 
 * - Estado del equipo: Si han pasado más de 3 minutos desde la última vez que
 *   el equipo estuvo activo (desde data.time_in_seconds), se considera que el
 *   equipo está fuera de línea y se alerta de ello. De lo contrario, se
 *   muestran otras alertas. Todo esto ocurre en StatusAlertGroup.
 * 
 * - Historial de uso: como este suele contener más de 2500 páginas, es mejor
 *   separarlo en varias páginas de 250 líneas. Se implementa un PageController
 *   para avanzar y retrocecer páginas.
 */
export default function StatusSection({ status }: StatusSectionProps): JSX.Element {
  const { lastSoftwareUnixTime, warning, deviceStatus, deviceLoad } = status;
  
  /**
   * StatusAlert entrega uno de los mensajes de alerta del estado del equipo.
   */
  function StatusAlert({ status, title, description }: StatusAlertProps): JSX.Element {
    if (description) {
      return <Alert borderRadius="8px" status={status}>
        <AlertIcon />
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>
      </Alert>;
    } else {
      return <></>;
    }
  }

  /**
   * StatusAlertGroup entrega todos los mensajes del estado del equipo.
   */
  function StatusAlertGroup(): JSX.Element {
    // La diferencia no deberia superar los 3 minutos
    const THRESHOLD = 180;
    const nowInSeconds = Date.now() / 1000;
    const offlineTimeInSeconds = nowInSeconds - lastSoftwareUnixTime;

    if (offlineTimeInSeconds > THRESHOLD) {
      return (
        <StatusAlert status="error" title={`Fuera de línea hace ${secondsToTimeString(offlineTimeInSeconds)}`} description=" " />
      );
    }

    return (
      <VStack spacing={2}>
        <StatusAlert status="warning" title="Alerta:" description={warning} />
        <StatusAlert status="info" title="Estado del equipo:" description={deviceStatus} />
        <StatusAlert status={deviceLoad[0] === ">" ? "warning" : "info"} title="Carga del último ciclo:" description={deviceLoad} />
        <StatusAlert status="info" title="Última comunicación con el equipo:" description={new Date(lastSoftwareUnixTime * 1000).toLocaleString("es-CL", { timeZone: "America/Santiago" })} />
      </VStack>
    );
  }


  return (
    <Box w={{"base": "100%", "lg": "50%"}} p="4" borderWidth="2px">
      <StatusAlertGroup />
    </Box>
  );
}