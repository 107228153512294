import { useEffect, useState } from "react";
import { IconButton } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";


// Basado en el código de https://stackoverflow.com/a/74063124
export default function ScrollToTopButton() {
	const [isVisible, setIsVisible] = useState(false);

	function scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		const toggleVisibility = () => setIsVisible(window.pageYOffset > 400);
		window.addEventListener("scroll", toggleVisibility);
		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	return (
		<>
			{isVisible && (
				<IconButton
					aria-label="Regresar al inicio de página"
					position="fixed"
					bottom="20px"
					right={{ "base": "16px", "md": "84px" }}
					zIndex={3}
					size={{ "base": "sm", "md": "md" }}
					borderRadius="full"
					icon={<ArrowUpIcon />}
					colorScheme="blue"
					variant="solid"
					onClick={scrollToTop}
				/>
			)}
		</>
	);
}