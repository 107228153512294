import * as React from "react";
import { Box, Center, Heading, HStack, Tag, TagLabel, VStack } from "@chakra-ui/react";

import ChamberTable from "./chamberTable/ChamberTable";
import DownloadButton from "./downloadButton/DownloadButton";
import { RecentData } from "../../../utils/interfaces";

/**
   * StatusTag se muestra al lado del nombre de la cámara e indica su estado.
   */
function StatusTag({status}: { status: number }): JSX.Element {
  if (status === 1) {
    return <Tag size="lg" variant="solid" colorScheme="green"><TagLabel>Habilitada</TagLabel></Tag>;
  } else if (status === 0) {
    return <Tag size="lg" variant="solid" colorScheme="yellow"><TagLabel>Deshabilitada</TagLabel></Tag>;
  } else {
    return <Tag size="lg" variant="solid" colorScheme="red"><TagLabel>Desactivada</TagLabel></Tag>;
  }
}

interface ChamberInfoProps {
  selectedUsername: string;
  localChamberID: number;
  recentData: RecentData;
  index: number;
  lastSetPointChangeDate: Date | null;
  chamberHasData: boolean;
}

/**
 * ChamberInfoSection muestra el estado y los datos más recientes de la cámara
 * actual.
 * 
 * Estructura:
 * 
 * - ChamberInfoSection
 *   - ChamberTable
 *   - DownloadButton
 */
export default function ChamberInfo({ selectedUsername, localChamberID, recentData, index, lastSetPointChangeDate, chamberHasData }: ChamberInfoProps): JSX.Element {
  const status = recentData.statuses[index];
  const title = recentData.titles[index];

  return (
    <Center>
      <VStack pl={{"base": 2, "lg": 0}} pr={{"base": 2, "lg": 4}} pt={{"base": 1, "md": 2}} pb={2} w={{"lg": "410px", "2xl": "480px"}} spacing={{"base": 2, "md": 3, "2xl": 4}}>
        {/* Nombre, estado y título de la cámara */}
        <VStack spacing={-0.5}>
          <HStack gap={2}>
            <Heading>Cámara {localChamberID}</Heading>
            <Box>
              <StatusTag status={status} />
            </Box>
          </HStack>
          { title ? <Heading as="h3" size="md" noOfLines={1}>{title}</Heading> : null }
        </VStack>
        
        {/* Tabla */}
        <ChamberTable recentData={recentData} index={index} lastSetPointChangeDate={lastSetPointChangeDate} />

        {/* Botón de descarga, si es que existen datos */}
        { chamberHasData ? <DownloadButton selectedUsername={selectedUsername} localChamberID={localChamberID} filename={`Cam${localChamberID} ${title}.csv`} /> : null }
      </VStack>
    </Center>
  );
}