import { Box, Button, Flex, Heading, HStack, IconButton, Image, Link, Select, Text, useToast, useDisclosure } from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";

import { User, UserConfig, UserState } from "../../utils/interfaces";
import { deleteJWT } from "../../utils/jwt";
import atmosfixLogo from "./atmosfix_logo.webp";
import ConfigModal from "./configModal/ConfigModal";
import { storeSelectedUsername } from "../../utils/selectedUsername";
import { useState } from "react";
import { EMPTY_CONFIG } from "../../utils/constants";
import { getUserConfig } from "../../utils/api";

interface HeaderProps {
  currentUser: User;
  setUserState: (newUser: React.SetStateAction<UserState>) => void;
  users: User[];
  selectedUsername: string;
  setSelectedUsername: (newQuery: React.SetStateAction<string>) => void;
}

/**
 * Header es la cabecera de la página.
 * 
 * Aquí se muestra el título "Monitoreo de Atmósferas Controladas <USER>" que
 * incluye el nombre del usuario, una imagen y un botón para cerrar sesión, que
 * requiere la función userSetter pasada como argumento.
 */
export default function Header({ currentUser, setUserState, users, selectedUsername, setSelectedUsername }: HeaderProps): JSX.Element {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [userConfig, setUserConfig] = useState<UserConfig>(EMPTY_CONFIG);

  function innerOnOpen() {
    getUserConfig(selectedUsername)
      .then(res => {
        setUserConfig(res.data);
        onOpen();
      });
  }

  /**
   * handleLogout es una función para cerrar sesión.
   */
  function handleLogout() {
    deleteJWT();
    toast.closeAll(); // Para evitar que queden toasts flotando al cerrar sesión
    setUserState({ isValidating: false, user: null });
  }

  /**
   * handleUserSelect es una función exclusiva para administradores que maneja
   * el cambio de usuario en el selector de usuarios.
   */
  function handleUserSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    toast.closeAll(); // Para evitar que queden toasts flotando al cambiar de usuario
    const newSelectedUsername = event.target.value;
    storeSelectedUsername(newSelectedUsername);
    setSelectedUsername(newSelectedUsername);
  }

  return (
    <Flex px={{"base": 2, "md": 3}} py={2} as="nav" align="center" justify="space-between" bg="teal.700">
      <Link href="https://happyvolt.com/atmosfix/" target="_blank" rel="noopener">
        <Box p={1} borderRadius="md" bg="white">
          <Image h={{"base": "24px", "md": "32px"}} src={atmosfixLogo} alt="AtmosFix" />
        </Box>
      </Link>

      <HStack>
        {
          currentUser.isAdmin
          ? 
          <Select w={{"base": 44, "md": 52}} size={{"base": "sm", "md": "md"}} bg="white" defaultValue={selectedUsername} onChange={handleUserSelect}>
            {users.map(u => <option value={u.username} key={u.id}>{`${u.id}. ${u.displayName}`}</option>)}
          </Select>
          : 
          <Heading as="h1" size="lg" color="white">
            {currentUser.displayName}
          </Heading>
        }
        <Text display={{"base": "none", "lg": "block"}} isTruncated color="white" fontSize="2xl">
          - Monitoreo de Atmósferas Controladas
        </Text>
      </HStack>
      
      <HStack>
        <IconButton
          aria-label="Ajustes"
          size={{"base": "sm", "md": "md"}}
          colorScheme="blue"
          onClick={innerOnOpen}
          icon={<SettingsIcon />}
        />

        <ConfigModal userConfig={userConfig} setUserConfig={setUserConfig} selectedUsername={selectedUsername} isOpen={isOpen} onClose={onClose} />

        <Button size={{"base": "sm", "md": "md"}} colorScheme="red" onClick={handleLogout}>
          Cerrar sesión
        </Button>
      </HStack>
    </Flex>
	);
}