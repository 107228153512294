import { Box, Button, Heading, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { postUserConfig } from "../../../utils/api";

import { UserConfig } from "../../../utils/interfaces";


interface ConfigModalProps {
	userConfig: UserConfig;
	setUserConfig: (newConfig: React.SetStateAction<UserConfig>) => void;
	selectedUsername: string;
	isOpen: boolean;
	onClose: () => void;
}

export default function ConfigModal({ userConfig, setUserConfig, selectedUsername, isOpen, onClose }: ConfigModalProps): JSX.Element {
	function onSubmit(event: React.FormEvent) {
		event.preventDefault();
		userConfig.mailRecipients = userConfig.mailRecipients.replaceAll(" ", "");

		/**
		 * POST /semaphore/config llama a la función POSTConfig, definida en
		 * semaphore-backend/afapi/post_config.go
		 */
		postUserConfig(selectedUsername, userConfig)
			.then(_ => {
				setUserConfig(userConfig);
				onClose();
			});
	}

	function onChangeBoolean(event: React.ChangeEvent<HTMLInputElement>) {
		// event.target se refiere al input en sí (Input, TimeInput, Radio)
		userConfig[event.target.name] = event.target.value === "true";
	}

	function onChangeString(event: React.ChangeEvent<HTMLInputElement>) {
		// event.target se refiere al input en sí (Input, TimeInput, Radio)
		userConfig[event.target.name] = event.target.value;
	}

	function MyHeading({ heading }: { heading: string }) {
		return <Heading as="h3" size="md" py={4}>{heading}</Heading>;
	}

	function MyLabel({ label }: { label: string }) {
		return <Box w="132px"><Text><b>{label}</b></Text></Box>;
	}

	return (
		<Modal size="xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Configuración</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<form onSubmit={onSubmit} method="post">
						{/* Correos */}
						<MyHeading heading="Correos" />
						<HStack>
							<MyLabel label="Enviar correos" />
							<RadioGroup name="enableUserAlarms" defaultValue={userConfig.enableUserAlarms.toString()}>
								<HStack spacing={4}>
									<Radio value="true" onChange={onChangeBoolean}>Sí</Radio>
									<Radio value="false" onChange={onChangeBoolean}>No</Radio>
								</HStack>
							</RadioGroup>
						</HStack>
						<HStack>
							<MyLabel label="Destinatarios" />
							<Input name="mailRecipients" defaultValue={userConfig.mailRecipients} onChange={onChangeString} placeholder="Correos separados por punto y coma (;)" htmlSize={40} width="auto" />
						</HStack>
						
						<Button mt={4} type="submit" shadow="lg">Actualizar</Button>
					</form>
				</ModalBody>
			</ModalContent>
		</Modal>
	)

}