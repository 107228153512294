import { useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";

import { Chart as ChartJS, PointElement, LineController, LineElement, TimeScale, LinearScale, Legend, Tooltip, TimeUnit, ChartData } from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import downsamplePlugin from "chartjs-plugin-downsample";
import "chartjs-adapter-luxon"; // Adaptador para usar escalas de tiempo con zoomPlugin

import noDataPlugin from "./noDataPlugin";
import { ChamberData, NoDataPluginOptions } from "../../../utils/interfaces";
import { Mode } from "chartjs-plugin-zoom/types/options";
import { Tick } from "chart.js";

ChartJS.register(PointElement, LineController, LineElement, TimeScale, LinearScale, Legend, Tooltip);


const O2BackgroundColor = "#90CDF4";              // blue.200
const O2BorderColor = "#4299E1";                  // blue.400
const CO2BackgroundColor = "#FEB2B2";             // red.200
const CO2BorderColor = "#F56565";                 // red.400
const PulpTemperatureBackgroundColor = "#9DECF9"; // cyan.200
const PulpTemperatureBorderColor = "#0BC5EA";     // cyan.400
const AirTemperatureBackgroundColor = "#FBB6CE";  // pink.200
const AirTemperatureBorderColor = "#ED64A6";      // pink.400
const HumidityBackgroundColor = "#9AE6B4";        // green.200
const HumidityBorderColor = "#48BB78";            // green.400

const DAY = 86400000; // milisegundos en un día
const HOUR = 3600000; // milisegundos en una hora

const NULL_VAL = 999.0;

interface Bounds {
  min: number;
  max: number;
};

type TickCallback = (tickValue: number, index: number, tick: Tick[]) => number;

interface ChamberChartProps {
  localChamberID: number;
  chamberData: ChamberData;
};

/**
 * ChamberChartSection muestra todas las mediciones de la cámara del ID
 * indicado por el parámetro localChamberID. Estas mediciones están en
 * chamberData.
 * 
 * ChamberChartSection usa el plugin zoomPlugin para poder hacer zoom y pan por
 * todos los datos. Así, por defecto se muestran los últimos 3 días de datos,
 * pero se puede navegar por ellos.
 * 
 * Hay un "conflicto" entre react-chartjs-2 y chartjs-plugin-zoom que provoca
 * que, cada vez que cambien los datos en chamberData, se resetee el zoom a los
 * valores originales al re-renderizar el gráfico.
 * 
 * Para evitarlo, se guarda el estado actual del zoom en "bounds", un arreglo
 * con el mínimo y máximo valor a mostrar en el gráfico en el momento actual.
 * Así, cada vez que se re-renderice el gráfico, se reusa el estado anterior de
 * "bounds". Sin embargo, si cambia la ID de la cámara o si se actualizan los
 * datos, el zoom se resetea al valor por defecto. Cada vez que se hace zoom o
 * pan, se gatillan los eventos onZoom y onPan que guardan el nuevo estado en
 * "bounds".
 */
export default function ChamberChart({ localChamberID, chamberData }: ChamberChartProps): JSX.Element {
  const { unixTimes, o2, o2SetPointGroups, co2, co2SetPointGroups, airTemperatures, pulpTemperatures, humidities } = chamberData;

  function flattenGroups(source: number[][]): number[] {
    const result: number[] = [];
    source.forEach(group => {
      const [value, repeatTimes] = group;
      for (var i = 0; i < repeatTimes; i++) {
        result.push(value);
      }
    })
    return result;
  }

  const o2SetPoints = flattenGroups(o2SetPointGroups);
  const co2SetPoints = flattenGroups(co2SetPointGroups);

  const dataBounds = new Map<string, Bounds>();
  const tickCallbacks = new Map<string, TickCallback>();
  const categories = new Map<string, [number[][], Bounds]>()
    .set("gas", [[ o2, co2, o2SetPoints, co2SetPoints], { min: 0, max: 25 }])
    .set("temperature", [[pulpTemperatures, airTemperatures], { min: -5, max: 30 }])
    .set("humidity", [[humidities], { min: 0, max: 100 }]);

  for (const [category, [attributes, attrBounds]] of categories) {
    const filteredArrs = attributes.map(attr => 
      attr.filter(val => val !== NULL_VAL)
    );
    const arrMins = filteredArrs.map(arr => Math.min(...arr));
    const arrMaxes = filteredArrs.map(arr => Math.max(...arr));
    const categoryMin = Math.min(attrBounds.min, ...arrMins);
    const categoryMax = Math.max(attrBounds.max, ...arrMaxes);
    
    tickCallbacks.set(category, (tickValue, index, ticks) => {
      if (tickValue < categoryMin) {
        tickValue = categoryMin;
        ticks[index].value = categoryMin;
        ticks[index].label = categoryMin.toString();
      }
      else if (tickValue > categoryMax) {
        tickValue = categoryMax;
        ticks[index].value = categoryMax;
        ticks[index].label = categoryMax.toString();
      }
      return tickValue;
    });

    const categorySize = categoryMax - categoryMin;
    
    dataBounds.set(category, {
      min: categoryMin - 0.04 * categorySize,
      max: categoryMax + 0.04 * categorySize,
    });
  }

  function getDataset(array: number[]) {
    return unixTimes.map((unixTime, i) => ({
      x: 1000 * unixTime,
      y: (array[i] === NULL_VAL) ? null : array[i],
    }));
  }

  const data: ChartData<"line"> = {
    datasets: [
      {
        label: "O₂",
        yAxisID: "yGas",
        data: getDataset(o2),
        backgroundColor: O2BackgroundColor,
        borderColor: O2BorderColor,
        borderWidth: 2.75,
        pointRadius: 4.5,
        pointHoverRadius: 6,
        pointBorderWidth: 1.75,
        pointHoverBorderWidth: 1.75,
      },
      {
        label: "CO₂",
        yAxisID: "yGas",
        data: getDataset(co2),
        backgroundColor: CO2BackgroundColor,
        borderColor: CO2BorderColor,
        borderWidth: 2.75,
        pointRadius: 4.5,
        pointHoverRadius: 6,
        pointBorderWidth: 1.75,
        pointHoverBorderWidth: 1.75,
      },
      {
        label: "SP O₂",
        yAxisID: "yGas",
        data: getDataset(o2SetPoints),
        backgroundColor: O2BackgroundColor,
        borderColor: O2BorderColor,
        borderWidth: 2,
        borderDash: [6, 4],
        pointRadius: 1,
        pointHoverRadius: 2,
        pointBorderWidth: 1,
        pointHoverBorderWidth: 1,
      },
      {
        label: "SP CO₂",
        yAxisID: "yGas",
        data: getDataset(co2SetPoints),
        backgroundColor: CO2BackgroundColor,
        borderColor: CO2BorderColor,
        borderWidth: 2,
        borderDash: [6, 4],
        pointRadius: 1,
        pointHoverRadius: 2,
        pointBorderWidth: 1,
        pointHoverBorderWidth: 1,
      },
      {
        label: "T° Pulpa",
        yAxisID: "yTemperature",
        data: getDataset(pulpTemperatures),
        backgroundColor: PulpTemperatureBackgroundColor,
        borderColor: PulpTemperatureBorderColor,
        borderWidth: 1,
        pointRadius: 3,
      },
      {
        label: "T° Aire",
        yAxisID: "yTemperature",
        data: getDataset(airTemperatures),
        backgroundColor: AirTemperatureBackgroundColor,
        borderColor: AirTemperatureBorderColor,
        borderWidth: 1,
        pointRadius: 3,
      },
      {
        label: "Humedad",
        yAxisID: "yHumidity",
        data: getDataset(humidities),
        backgroundColor: HumidityBackgroundColor,
        borderColor: HumidityBorderColor,
        borderWidth: 1,
        pointRadius: 3,
      },
    ]
  };  

	return (
    <Box
      minWidth={0} // NECESARIO para que el gráfico se encoja al achicar la página
      w="full" // NECESARIO para que ocupe todo el ancho disponible
      h={{"base": "200px", "sm": "240px", "md": "280px", "lg": "276px", "2xl": "360px"}}
      px={4}
      pt={2}
      pb={{"base": 1, "md": 2}}
    >
      <ChamberChartContent
        data={data}
        chamberData={chamberData}
        localChamberID={localChamberID}
        dataBounds={dataBounds}
        tickCallbacks={tickCallbacks}
      />
    </Box>
  );
}


interface ChamberChartContentProps {
  data: ChartData<"line">
  chamberData: ChamberData;
  localChamberID: number;
  dataBounds: Map<string, Bounds>;
  tickCallbacks: Map<string, TickCallback>;
}

function ChamberChartContent({ data, chamberData, localChamberID, dataBounds, tickCallbacks }: ChamberChartContentProps): JSX.Element {
  const { unixTimes } = chamberData;

  const isScreenLarge = (window.innerWidth >= 768);

  const LEN = unixTimes.length;
  const lastDate = (LEN >= 1) ? 1000*unixTimes[LEN-1] : 3*DAY;
  const nextToLastDate = (LEN >= 2) ? 1000*unixTimes[LEN-2] : 3*DAY - HOUR;

  const [bounds, setBounds] = useState<Bounds>({
    min: lastDate - 3*DAY,
    max: lastDate + HOUR,
  });
  const boundsRef = useRef(bounds); // necesario para referenciar a bounds en useEffect sin agregarlo en el arreglo de dependencias

  useEffect(() => {
    setBounds({ min: lastDate - 3*DAY, max: lastDate + HOUR });
  }, [localChamberID, lastDate]);

  useEffect(() => {
    const bounds = boundsRef.current;
    if (bounds[0] === nextToLastDate - 3*DAY && bounds[1] === nextToLastDate + HOUR) {
      setBounds({ min: lastDate - 3*DAY, max: lastDate + HOUR });
    }
  }, [chamberData, lastDate, nextToLastDate]);

  const options = {
    // Parámetros para optimizar renderizado
    spanGaps: true,
    normalized: true,
    parsing: false as false,
    animation: false as false,

    // Parámetros para responsividad
    responsive: true,
    maintainAspectRatio: false,

    layout: {
      padding: { left: 4, right: 4, top: -2 },
    },

    // Opciones de ejes
    scales: {
      x: {
        type: "time" as "time",
        min: bounds.min,
        max: bounds.max,
        time: {
          minUnit: "second" as TimeUnit,
          displayFormats: { hour: "H:MM" },
        },
        ticks: {
          major: { enabled: true },
          font(ctx: any) {
            if (ctx.tick && ctx.tick.major) {
              return { size: 14, weight: "bold" };
            }
          },
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          color(ctx: any) {
            return ctx.tick.major ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.1)";
          }
        }
      }, 
      yGas: {
        min: dataBounds.get("gas").min,
        max: dataBounds.get("gas").max,
        title: {
          display: true,
          text: "Concentración de gas [%]",
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 1,
        },
        ticks: {
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 0,
          callback: tickCallbacks.get("gas"),
        },
        grid: { display: false },
      },
      yTemperature: {
        min: dataBounds.get("temperature").min,
        max: dataBounds.get("temperature").max,
        title: {
          display: true,
          text: "Temperatura [°C]",
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 1,
        },
        ticks: {
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 0,
          callback: tickCallbacks.get("temperature"),
        },
        position: "right" as "right",
        grid: { display: false },
      },
      yHumidity: {
        min: dataBounds.get("humidity").min,
        max: dataBounds.get("humidity").max,
        title: {
          display: true,
          text: "Humedad [%]",
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 1,
        },
        ticks: {
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 0,
          callback: tickCallbacks.get("humidity"),
        },
        position: "right" as "right",
      },
    },

    // Opciones de plugins
    plugins: {
      downsample: {
        enabled: true,
        threshold: 50, // change this

        auto: false, // don't re-downsample the data every move
        onInit: true, // but do resample it when we init the chart (this is default)

        preferOriginalData: true, // use our original data when downscaling so we can downscale less, if we need to.
        restoreOriginalData: false, // if auto is false and this is true, original data will be restored on pan/zoom - that isn't what we want.
      },
      legend: { labels: {boxWidth: isScreenLarge ? 32 : 24} },
      tooltip: {
        callbacks: {
          label(tooltipItem: any) {
            if (tooltipItem.dataset.label === "T° Pulpa" || tooltipItem.dataset.label === "T° Aire") {
              return tooltipItem.dataset.label + ": " + parseFloat(tooltipItem.formattedValue.replace(",", ".")).toFixed(1).replace(".", ",") + "°C";
            } else {
              return tooltipItem.dataset.label + ": " + tooltipItem.formattedValue + "%";
            }
          }
        }
      },
      zoom: {
        // onZoom y onPan constantemente guardan los límites inferior y superior a mostrar del gráfico
        zoom: {
          wheel: {enabled: true},
          pinch: {enabled: true},
          mode: "x" as Mode,
          onZoom: (chart: { chart: ChartJS }) => setBounds({
            min: chart.chart.scales.x.min,
            max: chart.chart.scales.x.max,
          }),
        },
        pan: {
          enabled: true,
          mode: "x" as Mode,
          onPan: (chart: { chart: ChartJS }) => setBounds({
            min: chart.chart.scales.x.min,
            max: chart.chart.scales.x.max,
          }),
        },
        limits: {
          x: {
            min: (LEN > 0) ? 1000*unixTimes[0] - HOUR     : 0,
            max: (LEN > 0) ? 1000*unixTimes[LEN-1] + HOUR : 3*DAY + HOUR,
          }
        }
      },
      noDataPlugin: { noData: (LEN === 0) } as NoDataPluginOptions,
    }
  };

  return (
    <Line
      data={data}
      plugins={[zoomPlugin, downsamplePlugin, noDataPlugin]}
      options={options}
    />
  );
}