import { useState, useEffect } from "react";
import { Box, Highlight, Heading, Text, VStack } from "@chakra-ui/react";
import { StatusRecord } from "../../utils/interfaces";

/**
 * CylinderHighlight recibe un texto de la forma [1],3,4, donde los números son
 * cilindros llenos y el número entre corchetes es el que se está usando
 * actualmente. El número entre corchetes lo destaca con color.
 */
function CylinderHighlight({ children }: { children: string }): JSX.Element {
  const currentCylinders = [];
  const cylinderArray = children.split(",");
  const numCylinders = cylinderArray.length;
  for (var i = 0; i < numCylinders; i++) {
    if (cylinderArray[i][0] === "[") {
      cylinderArray[i] = cylinderArray[i].slice(1, -1)
      currentCylinders.push(cylinderArray[i])
    }
  }
  const processedText = cylinderArray.join(" - ");

  return (
    <Highlight query={currentCylinders} styles={{ px: "1", py: "0.5", rounded: "4", bg: "blue.200" }}>
      {processedText}
    </Highlight>
  );
}

/**
 * HUDSection muestra datos relevantes del equipo: cuántos cilindros llenos y
 * vacíos quedan, la hora del último y próximo control, y la hora local.
 */
export default function HUD({ status }: { status: StatusRecord }): JSX.Element {
  status.timeZone = "America/Santiago"; // TODO: eliminar esto en el futuro
  const { timeZone, lastSoftwareUnixTime, lastControlTime, nextControlTime, fullCylinders, emptyCylinders, deviceStatus } = status;

  var currentUnixTime = new Date().valueOf() / 1000;
  const [softwareTimeInSeconds, setSoftwareTimeInSeconds] = useState(lastSoftwareUnixTime);

  useEffect(() => {
    const interval = setInterval(() => {
      if (lastSoftwareUnixTime < currentUnixTime - 180) {
        setSoftwareTimeInSeconds(lastSoftwareUnixTime);
      } else {
        currentUnixTime++;
        setSoftwareTimeInSeconds(softwareTimeInSeconds + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  });
  useEffect(() => {
    setSoftwareTimeInSeconds(lastSoftwareUnixTime);
  }, [lastSoftwareUnixTime]);


  return (
    <>
      {/* Mostrar en pantalla pequeña */}
      <VStack w="full" display={{"base": "flex", "lg": "none"}} lineHeight={1.2} spacing={0}>
        <Text><b>Cilindros de N₂ llenos:</b> { fullCylinders ? <CylinderHighlight>{fullCylinders}</CylinderHighlight> : "Sin información" }</Text>
        <Text><b>Cilindros de N₂ vacíos:</b> { emptyCylinders ? <CylinderHighlight>{emptyCylinders}</CylinderHighlight> : "Sin información" }</Text>
        <Text><b>Último control:</b> { lastControlTime ? lastControlTime : "Sin información" }</Text>
        <Text><b>Próximo control:</b> { nextControlTime ? nextControlTime : "Sin información" }</Text>
        <Text><b>Estado del equipo:</b> { deviceStatus }</Text>
        <Text color={lastSoftwareUnixTime < currentUnixTime - 180 ? "red.500" : "black"}><b>Hora del equipo:</b> { new Date(1000 * softwareTimeInSeconds).toLocaleString("es-cl", { timeZone: timeZone }) }</Text>
      </VStack>

      {/* Mostrar en pantalla grande */}
      <Box w="full" display={{"base": "none", "lg": "flex"}} justifyContent="space-evenly">
        <VStack lineHeight={1.2} spacing={0}>
          <Heading as="h3" size="lg">Cilindros de N₂</Heading>
          <Text><b>Llenos:</b> { fullCylinders ? <CylinderHighlight>{fullCylinders}</CylinderHighlight> : "Sin información" }</Text>
          <Text><b>Vacíos:</b> { emptyCylinders ? <CylinderHighlight>{emptyCylinders}</CylinderHighlight> : "Sin información" }</Text>
        </VStack>

        <VStack lineHeight={1.2} spacing={0}>
          <Heading as="h3" size="lg">Controles</Heading>
          <Text><b>Último:</b> { lastControlTime ? lastControlTime : "Sin información" }</Text>
          <Text><b>Próximo:</b> { nextControlTime ? nextControlTime : "Sin información" }</Text>
        </VStack>

        <VStack lineHeight={1.2} spacing={0}>
          <Heading as="h3" size="lg">Estado del equipo</Heading>
          <Text fontSize="2xl">{ deviceStatus }</Text>
        </VStack>

        <VStack lineHeight={1.2} spacing={0} color={lastSoftwareUnixTime < currentUnixTime - 180 ? "red.500" : "black"}>
          <Heading as="h3" size="lg">Hora del equipo</Heading>
          <Text fontSize="2xl">{ new Date(1000 * softwareTimeInSeconds).toLocaleString("es-cl", { timeZone: timeZone }) }</Text>
        </VStack>
      </Box>
    </>
    
  );
}

